.sidebar{
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    /* background-color: red;
    color: black; */
    /* width: 100%;
    height: 100%; */
    /* left: 00px; */
    /* position: fixed; */
    position: fixed;
    top: 0;
    right: 0;
    /* right: 0; */
    /* margin-left: min(0, 100vw - 400px); */
    /* background-color: purple; */
    width: 400px;
    height: 100vh;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    /* max-width: 400vw; */
    /* transform: scale(1); */
    .bg{
        overflow: hidden;
        position: fixed;
        /* left: 400px; */
        /* align-items: end;
        justify-content: end; */
        top: 0;
        right: 0;
        /* right: 0; */
        /* left: calc((100vw - 400px)); */
        /* right: min(calc((400px - 100vw)), 0); */
        /* left: min(0, 100vw - 400px); */
        /* right: 0%(calc(400px - 100vw), 0); */
         /* min(0, calc(100vw - 400px)); */
         
        bottom: 0;
        margin: auto;
        width: 400px;
        /* max-width: 75%; */
        /* background: linear-gradient( 109.5deg,  rgba(76,221,242,1) 11.2%, rgba(92,121,255,1) 91.1% );  */
        /* background: linear-gradient( 199.5deg,  rgba(106,185,249,1) 11.2%, rgba(76,165,229,1) 91.1% );  */
        background: rgba(106,185,249,1.0) ;
        /* rgb(250,250,250); */
        .links{
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;
            .side_button{
                font-size: 40px;
                color: black;
                text-decoration: none;
            }
        }
    }
    .hamburger_button{
        pointer-events: auto;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        position: absolute;
        /* top: 30px;
        right: 25px; */
        background-color: transparent;
        border: none;
        cursor: pointer;
        right: 500px;
    }
}
.interactable{
    pointer-events: all;
}
.nonInteractable{
    pointer-events: none;
}
.side_button{
    margin-left: max(0px, calc(1 * calc(400px - 100vw)));
    background-color: transparent;
    border: 0;
}