.cyanSpacer{
    aspect-ratio: 1800/600;
    height: 100%;
    max-height: min(80vw, 1100px);
    min-height: 750px;
    width: 100%;
    /* min-width: 100%; */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
}
.cyanTop{
    background-image: url('../../img/svg/cyan-top.svg');
}
.bbScrollSenseDiv{
    height: 100px;
    width: 100%;
    position: absolute;
    top: 250px;
    margin: -20px;
    pointer-events: none;
}
.bbBoat1{
    background-image: url('../../img/svg/BlunderBoats/ship1.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
}
.bbBoat2{
    background-image: url('../../img/svg/BlunderBoats/ship2.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
}
.bbBoat3{
    background-image: url('../../img/svg/BlunderBoats/ship3.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
}
.dottedPath{
    background-image: url('../../img/svg/BlunderBoats/dottedPath.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
}
.bbProjectile1{
    background-image: url('../../img/svg/BlunderBoats/Projectile1.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
}
.bbProjectileGraph{
    background-image: url('../../img/svg/BlunderBoats/projectileGraph.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    position: absolute;
}
.blunderBoatsTextBox h3{
    color: black;
    padding-top: 22px;
    padding-left: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
}
.blunderBoatsTextBox h5{
    color: black;
    padding-top: 16px;
    padding-left: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
}
.bbTextBoxSideBar{
    width: 3px;
    height: 100px;
    position: absolute;
    background-color: black;
    top: 0;
}
.bbTextBoxTopBar{
    width: 100px;
    height: 3px;
    position: absolute;
    background-color: black;
    top: 0;
}
.bblrTextBoxSideBar{
    width: 3px;
    height: 100px;
    position: absolute;
    background-color: black;
    bottom: 0;
}
.bblrTextBoxBottomBar{
    width: 100px;
    height: 3px;
    position: absolute;
    background-color: black;
    bottom: 0;
}
.blunderBoatsTextBox{
    pointer-events: none;
    padding-bottom: 14px;
    padding-right: 12px;
}
.blunderBoatsTextBox form{
    top: 0px;
    /* width: 100%; */
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    flex-direction: row;
    /* background-color: blue; */
    position: relative;
    pointer-events: all;
}
.blunderBoatsTextBox form *{
    width: 100%;
    padding-left: 6px;
    padding-right: 6px;
    max-width: 200px;
}
.blunderBoatsTextBox form button{
    width: 220px;
    text-align: left;
    pointer-events: auto;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    border-radius: 5px;
    font-size: 1rem;
    font-family: inherit;
    background-color: rgba(109,185,249,1);
    color: black;
    border: 1px solid transparent;
    transition: 0.3s ease-out;
}
.blunderBoatsTextBox form button:hover{
    border-color: rgba(109,185,249,1);
    background: rgba(255,255,255, 0.1);
    color: rgba(109,185,249,1);
}
.blunderBoatsTextBox form button:active{
    border-radius: 10px;
    border-color: rgba(109,185,249,1);
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.05));
    color: rgba(109,185,249,1);
}
.proceduralCanvas{
    width: 100%;
    height: 100%;
    background-color: gray;
    margin: auto;
}
.proceduralGraphParent{
    aspect-ratio: auto 5/4;
}
.proceduralGraphParent{
    pointer-events: none;
}
@media (max-width: 800px){
    .proceduralGraphParent{
        position: absolute;
        top: 1900px;
        width: 70%;
        left: 0;
        right: 0;
        margin: auto;
    }
    .bbProjectileGraph{
        width: min(150px, 60vw);
        height: 300px;
        top: 1120px;
        left: 0;
        right: 0;
        margin: auto;
        background-position: top;
    }
    .rightBar{
        right: 0;
    }
    .leftBar{
        left: 0;
    }
    .blunderBoatsTextBox{
        /* width: 100%; */
        min-height: 20vh;
        max-width: 90vw;
        min-width: 20vw;
        padding-bottom: 16px;
    }
    .bbProjNameBox{
        width: min(500px, 80vw);
    }
    .bbBoat1{
        width: 150px;
        height: 100px;
        top: 760px;
        right: 34px;
    }
    .bbBoat2{
        width: 200px;
        height: 200px;
        max-width: 40vw;
        top: 2800px;
        right: 20px;
    }
    .bbBoat3{
        width: 200px;
        max-width: 40vw;
        height: 200px;
        top: 2850px;
        left: 20px;
    }
    .bbPage{
        height: 3800px;
    }
    .bbProjectile1{
        width: 400px;
        height: 400px;
        top: 615px;
        right: 132px;
        /* transform: rotate(-110deg); */
    }
    .bbTextBox1{
        position: absolute;
        top: 800px;
        left: 10px;
    }
    .bbTextBox2{
        position: absolute;
        top: 1400px;
        left: 10px;
    }
    .bbTextBox3{
        position: absolute;
        top: 2450px;
        left: 10px;
    }
    .dottedPath{
        width: 300px;
        max-width: 50%;
        height: 300px;
        top: 3000px;
        right: 0;
        left: 0;
        margin: auto;
    }
    .island1Container{
        /* aspect-ratio: 794/581; */
        width: 600px;
        /* max-width: 98%; */
        height: 600px;
        /* min(1000px, 50vh); */
        /* min-width: 500px; */
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        top: 0px;
    }
    }
.fill{
    width: 100%;
    height: 100%;
    position: absolute;
}
@media (max-width: 600px){
    .bbTextBox2{
        position: absolute;
        top: 1450px;
        left: 10px;
    }
    .proceduralGraphParent{
        position: absolute;
        top: 2000px;
        width: 90%;
        left: 0;
        right: 0;
        margin: auto;
    }
    .bbProjectileGraph{
        width: min(150px, 60vw);
        height: 300px;
        top: 1200px;
        left: 22px;
        background-position: top;
    }
    .island1Container{
        /* width: 600px;
        max-width: 98%;
        height: min(1000px, 46vh);
        position: absolute;
        right: 0;
        left: 0;
        margin: auto; */
        /* top: 70px; */
    }
    }
@media (min-width: 800px){
    .proceduralGraphParent{
        position: absolute;
        top: 2200px;
        width: 50%;
        left: 0;
        right: 0;
        margin: auto;
    }
    .bbProjectileGraph{
        width: min(300px, 18vw);
        height: 400px;
        top: 1135px;
        left: 650px;
        background-position: bottom;
    }
    .bbBoat1{
        width: 200px;
        height: 166px;
        top: 1060px;
        right: 100px;
    }
    .bbBoat2{
        width: 250px;
        height: 200px;
        top: 2750px;
        right: 80px;
    }
    .bbBoat3{
        width: 200px;
        height: 200px;
        top: 2850px;
        left: 80px;
    }
    .bbProjectile1{
        width: 528px;
        height: 528px;
        top: 895px;
        right: 238px;
        /* transform: rotate(-110deg); */
    }
    .bbPage{
        height: 3600px;
    }
    .rightBar{
        right: 0;
    }
    .leftBar{
        left: 0;
    }
    .blunderBoatsTextBox{
        position: relative;
        width: 100%;
        max-width: 600px;
        min-height: 10vh;
        /* left: 30px; */
        left: 30px;
        right: 48%;
        margin: auto;
        padding-bottom: 16px;
    }
    .bbTextBox1{
        position: absolute;
        top: 1200px;
    }
    .bbTextBox2{
        position: absolute;
        top: 1700px;
    }
    .bbTextBox3{
        position: absolute;
        top: 2600px;
    }
    .dottedPath{
        width: 300px;
        height: 300px;
        top: 2800px;
        right: 0;
        left: 0;
        margin: auto;
    }
.island1Container{
    /* aspect-ratio: 794/581; */
    width: 800px;
    /* max-width: 100%; */
    height: 1000px;
    /* min(1000px, 80vh); */
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    top: -160px;
}
}
@media (min-width: 1000px){
    .proceduralGraphParent{
        position: absolute;
        top: 1740px;
        width: calc(100vw - 800px);
        max-width: 600px;
        right: 0;
        left: 50%;
        margin: auto;
        /* height: 600px; */
    }
    .bbProjectileGraph{
        width: min(250px, 20%);
        height: 600px;
        top: 1335px;
        left: 40px;
        background-position: top;
    }
    .bbBoat1{
        width: 300px;
        height: 200px;
        top: 1100px;
        right: calc(30px + 5vw);
    }
    .bbBoat2{
        width: 250px;
        height: 200px;
        top: 2250px;
        right: 150px;
    }
    .bbBoat3{
        width: 200px;
        height: 200px;
        top: 2500px;
        left: 200px;
    }
    .dottedPath{
        width: 500px;
        height: 500px;
        top: 2500px;
        right: 0;
        left: 300px;
        margin: auto;
    }
    .bbProjectile1{
        width: 800px;
        height: 800px;
        top: 835px;
        right: calc(228px + 5vw);
        /* transform: rotate(-110deg); */
    }
    .bbTextBox1{
        position: absolute;
        top: 1000px;
        left: 0;
        right: 50%;
        margin: auto;
    }
    .bbTextBox2{
        position: absolute;
        top: 1700px;
        left: 0;
        right: 50%;
        margin: auto;
    }
    .bbTextBox3{
        position: absolute;
        top: 2300px;
        left: 0;
        right: 50%;
        margin: auto;
    }
}
@media (min-width: 1200px){
    .dottedPath{
        width: 500px;
        height: 500px;
        top: 2250px;
        right: 0;
        left: 300px;
        margin: auto;
    }
    .proceduralGraphParent{
        position: absolute;
        top: 1620px;
        width: calc(100vw - 650px);
        max-width: 500px;
        right: 0;
        left: 50%;
        margin: auto;
        /* height: 600px; */
    }
    .bbProjectileGraph{
        width: min(200px, 14vw);
        height: 400px;
        top: 935px;
        left: calc(280px + 35%);
        background-position: bottom;
    }
    .bbTextBox2{
        position: absolute;
        top: 1600px;
    }
    .bbTextBox3{
        position: absolute;
        top: 2300px;
    }
}
.cloudTop{
    background-image: url('../../img/svg/BlunderBoats/cloudTop.svg');
    aspect-ratio: 807/298;
    width: 98%;
    max-width: 560px;
    /* min-width: 400px; */
    right: 0;
    left: 0;
    margin: auto;
    position: absolute;
    top: 10px;
    background-repeat: no-repeat;
}
.cloudSide1{
    background-image: url('../../img/svg/BlunderBoats/cloudSide-1.svg');
    width: 300px;
    height: 100px;
    /* max-width: 560px; */
    /* min-width: 400px; */
    right: calc(50% - 680px);
    position: absolute;
    top: 80px;
    background-repeat: no-repeat;
}
.cloudSide2{
    background-image: url('../../img/svg/BlunderBoats/cloudSide-2.svg');
    width: 150px;
    height: 100px;
    /* max-width: 560px; */
    /* min-width: 400px; */
    right: calc(50% + 340px);
    position: absolute;
    top: 150px;
    background-repeat: no-repeat;
}
.cloudSide3{
    background-image: url('../../img/svg/BlunderBoats/cloudSide-3.svg');
    width: 150px;
    height: 100px;
    /* max-width: 560px; */
    /* min-width: 400px; */
    right: calc(50% + 520px);
    position: absolute;
    top: 80px;
    background-repeat: no-repeat;
}
.i1-s1{
    background-image: url('../../img/svg/BlunderBoats/Island1/i1-s1.svg');
    background-repeat: no-repeat;
    background-position: center;
}
.i1-s2{
    background-image: url('../../img/svg/BlunderBoats/Island1/i1-s2.svg');
    background-repeat: no-repeat;
    background-position: center;
}
.i1-s3{
    background-image: url('../../img/svg/BlunderBoats/Island1/i1-s3.svg');
    background-repeat: no-repeat;
    background-position: center;
}
.i1-l1{
    background-image: url('../../img/svg/BlunderBoats/Island1/i1-l1.svg');
    background-repeat: no-repeat;
    background-position: center;
}
.i1-l2{
    background-image: url('../../img/svg/BlunderBoats/Island1/i1-l2.svg');
    background-repeat: no-repeat;
    background-position: center;
}
.i1-l3{
    background-image: url('../../img/svg/BlunderBoats/Island1/i1-l3.svg');
    background-repeat: no-repeat;
    background-position: center;
}
.i1-l4{
    background-image: url('../../img/svg/BlunderBoats/Island1/i1-l4.svg');
    background-repeat: no-repeat;
    background-position: center;
}
.i1-l5{
    background-image: url('../../img/svg/BlunderBoats/Island1/i1-l5.svg');
    background-repeat: no-repeat;
    background-position: center;
}
.i1-l6{
    background-image: url('../../img/svg/BlunderBoats/Island1/i1-l6.svg');
    background-repeat: no-repeat;
    background-position: center;
}
.cloudFieldEnd{
    background-image: url('../../img/svg/BlunderBoats/cloud-field-end.svg');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 150px;
    margin: 0;
    padding: 0;
    top: 249px;
  }
  .cloudFieldMiddle{
    background-color: #4bc3e6;
    width: 100%;
    height: 250px;
    position: absolute;
    top: 0;
  }
  @media (max-width: 600px){
    .island1Container{
        /* margin-left: 0;
        margin-right: 0;
        margin: 'auto'; */
        left: calc((100vw - 600px) / 2);
        right: auto;
    }
} 