.carouselParent{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.carouselContainer {
  /* width: 100vw;
  height: 100vh; */
  position: absolute;
  pointer-events: none;
  display: flex;
  /* display: inline-block; */
  align-items: center;
  justify-content: center;
  top: 332px;
  /* height: 10%; */
  width: 100%;
  /* overflow: hidden; */
  /* background-image: blue; */
  /* background-color: rgba(0,255,150,0.2); */
  /* linear-gradient(45deg, blue, green); */
  /* font-family: 'Montserrat', sans-serif; */
}
@media (max-width: 800px){
  .carouselContainer {
    top: 310px;
  }
}
.carouselButton{
  border-radius: 20px;
  border: none;
  background-color: transparent;
  pointer-events: all;
}
  .carouselButton:hover {
    /* scale: 1.2;
    transform-origin: center center; */
    opacity: 1;
    background: rgba(255,255,255, 0.1);
    /* background-color: orange; */
  }
  .carouselButton:active {
    opacity: 0.6;
    background: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.3));
    border-radius: 40px;  
  }
@media (min-width: 800px){
  .carousel {
    position: relative;
    width:  300px;
    height: 300px;
    perspective: 800px;
    transform-style: preserve-3d;
    transition: 0.5s ease-out;
  }
  .applicationImage{
    scroll-snap-align: none;
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 17%;
    transition: 0.5s ease-out;
}
/* button {
  scale: 1;
  transform-origin: center center;
  opacity: 0.9;
  width: 90px;
  height: 90px;
  transition: 0.2s ease-out;
  background-color: red;
} */
.appTitle{
  scroll-snap-align: none;
  scroll-behavior: smooth;
  margin-top: 0px;
  padding-top: 0px;
  font-size: 38px;
}
}
@media (max-width: 800px){
  .carousel {
    position: relative;
    width:  120px;
    height: 120px;
    perspective: 800px;
    transform-style: preserve-3d;
    transition: 0.5s ease-out;
  }
  .applicationImage{
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 17%;
    transition: 0.5s ease-out;
}
/* button {
  scale: 1;
  transform-origin: center center;
  opacity: 0.9;
  width: 90px;
  height: 90px;
  transition: 0.2s ease-out;
  background-color: red;
} */
.appTitle{
  margin-top: 4px;
  padding-top: 0px;
  font-size: 32px;
}
}

.nav {
  color: white;
  font-size: 5rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0%;
  top: 50%;
  z-index: 2;
  cursor: pointer;
  /* user-select: none;
  background: unset;
  border: unset; */
  width: 60px;
  height: 60px;
  
  &.left {
    transform: translateX(-100%) translatey(-60%);
  }
  
  &.right {
    right: 0;
    transform: translateX(100%) translatey(-60%);
  }
}
.card-container {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: 
    rotateY(calc(var(--offset) * 80deg)) 
    scaleY(calc(1 + var(--abs-offset) * -0.4))
    translateZ(calc(var(--abs-offset) * -30rem))
    translateX(calc(var(--direction) * -3rem));
  /* filter: blur(calc(var(--abs-offset) * 1rem)); */
  transition: all 0.3s ease-out;
}